import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { getDateDiffFromNow, Spacing, Typography } from '@community-group/components';

import { Breadcrumbs as CommonBreadcrumbs } from '@/components/common/Breadcrumbs';
import ContentAuthorProfile from '@/components/common/ContentAuthorProfile';

import ChallengeDetailInfoList from './ChallengeDetailInfoList';
import ChallengeJoinedCountMessage from './ChallengeJoinedCountMessage';
import ChallengeTitle from './ChallengeTitle';
import * as s from './style.css';

type Props = {
  challengeDetail: ChallengeDetailPresentation;
};

const ChallengeInfoSection = ({ challengeDetail }: Props) => {
  const createdAtText =
    getDateDiffFromNow(new Date(Date.parse(challengeDetail.createdAt))).text + ' 전';

  return (
    <div className={s.Wrapper}>
      <CommonBreadcrumbs prefixIconType="challenge" items={['챌린지']} />
      <Spacing size={16} />
      <ContentAuthorProfile
        profileImage={challengeDetail.author.profileImage}
        nickname={challengeDetail.author.nickname}
        role={challengeDetail.author.role}
      >
        <Typography typography="caption2Regular" color="gray600">
          {createdAtText}
        </Typography>
      </ContentAuthorProfile>
      <Spacing size={16} />
      <ChallengeTitle status={challengeDetail.status} name={challengeDetail.name} />
      <Spacing size={16} />
      <ChallengeDetailInfoList
        endedAt={challengeDetail.endedAt}
        status={challengeDetail.status}
        certifyIntervalTargetCount={challengeDetail.certifyIntervalTargetCount}
        totalDays={challengeDetail.totalDays}
        startedAt={challengeDetail.startedAt}
      />
      <Spacing size={16} />
      <Typography typography="bodyL1Regular" color="gray900">
        {challengeDetail.description}
      </Typography>
      <Spacing size={16} />
      <ChallengeJoinedCountMessage
        participantsPreview={challengeDetail.participantsPreview}
        isJoined={challengeDetail.isJoined}
        currentUserNickname={challengeDetail.author.nickname}
      />
    </div>
  );
};

export default ChallengeInfoSection;
