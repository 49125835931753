import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import {
  Spacing,
  Switch,
  Typography,
  useSnackbarAdapter,
  VerticalSpacing,
} from '@community-group/components';
import { IconNotificationRegular } from '@seed-design/icon';

import { useGetChallengeNotificationSetting } from '@/api/hooks/useGetChallengeNotificationSetting';
import usePatchChallengeNotificationSetting from '@/api/hooks/usePatchChallengeNotificationSetting';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

import * as s from './style.css';

type Props = {
  groupId: number;
  challengeDetail: ChallengeDetailPresentation;
};

const RemindNotificationSection = ({ groupId, challengeDetail }: Props) => {
  const handleErrorWithToast = useHandleErrorWithToast();
  const snackbarAdapter = useSnackbarAdapter();

  const { data: notificationSetting } = useGetChallengeNotificationSetting(
    groupId,
    challengeDetail.id
  );
  const { mutate: patchNotificationSetting } = usePatchChallengeNotificationSetting({
    onError: handleErrorWithToast,
    onSuccess: () => {
      snackbarAdapter.create({
        message: '인증 리마인드 알림을 설정했어요.',
        type: 'default',
        timeout: 3000,
      });
    },
  });
  if (!challengeDetail) return null;

  return (
    <div className={s.Wrapper}>
      <div className={s.MainWrapper}>
        <div className={s.IconWrapper}>
          <IconNotificationRegular size={24} />
        </div>
        <VerticalSpacing size={12} />
        <div className={s.TitleWrapper}>
          <Typography typography="bodyM1Regular">인증 리마인드 알림</Typography>
          <Spacing size={2} />
          <Typography typography="caption1Regular" color="gray600">
            인증을 잊지 않도록 알림을 보내드려요.
          </Typography>
        </div>
      </div>
      <div className={s.SwitchWrapper}>
        <Switch
          isSelected={notificationSetting?.isReminderEnabled ?? false}
          onChange={(value) => {
            patchNotificationSetting({
              id: groupId,
              challengeId: challengeDetail.id,
              form: { isReminderEnabled: value },
            });
          }}
        />
      </div>
    </div>
  );
};

export default RemindNotificationSection;
