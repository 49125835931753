import { Divider, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetChallengeDetail } from '@/api/hooks/useGetCallengeDetail';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import ChallengeInfoSection from '../components/InfoSection';
import MoreMenu from '../components/MoreMenu';
import MyChallengeStatusSection from '../components/MyChallengeStatusSection';
import RemindNotificationSection from '../components/RemindNotificationSection';
import * as s from './GroupChallengeDetailPage.css';

type Params = Pick<PageParams, 'groupId' | 'challengeId'>;

const GroupChallengeDetailPage: ActivityComponentType<Params> = () => {
  const { groupId, challengeId } = usePathParams();
  const { data: challengeDetail } = useGetChallengeDetail({
    groupId,
    challengeId,
  });

  return (
    <AppScreen
      disabledClientOnly
      appBar={{
        title: (
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <Typography typography="title3Bold" ellipsisAfterLines={1}>
              {challengeDetail.groupName}
            </Typography>
          </div>
        ),
        renderRight: () => <MoreMenu />,
      }}
      onPull={() => {
        console.log('pull');
      }}
    >
      <div className={s.Wrapper}>
        <ChallengeInfoSection challengeDetail={challengeDetail} />
        <Divider padding={0} />
        {challengeDetail.isJoined && (
          <>
            <RemindNotificationSection
              groupId={Number(groupId)}
              challengeDetail={challengeDetail}
            />
            <Divider padding={0} />
            <MyChallengeStatusSection />
          </>
        )}

        {/* 최하단 여백 40 */}
        <Spacing size={40} />
      </div>
    </AppScreen>
  );
};

export default GroupChallengeDetailPage;
