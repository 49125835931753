import lottieLight from 'lottie-web/build/player/lottie_light.min.js';
import React, { useEffect, useRef } from 'react';

export type LottieProps = {
  autoplay?: boolean;
  loop?: boolean | number;
  animationData: any;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'id'>;

const Lottie = ({ autoplay = true, loop = true, animationData, ...elementProps }: LottieProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const lottieData = lottieLight.loadAnimation({
      container,
      renderer: 'svg',
      autoplay,
      loop,
      animationData,
    });

    lottieData.addEventListener('data_ready', () => {
      lottieData.play();
    });

    return () => {
      lottieData.destroy();
    };
  }, [animationData, autoplay, loop]);

  return <div ref={containerRef} {...elementProps} />;
};

export default Lottie;
