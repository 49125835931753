import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getChallengeNotificationSettingPath = (id: number, challengeId: number) =>
  groupClient.api.GroupChallengeApi.getapiV1GroupsGroupIdChallengesChallengeIdNotificationSettingsMeGetPath(
    id,
    challengeId
  );

export const useGetChallengeNotificationSetting = (id: number, challengeId: number) => {
  const fetchInstance = useFetchInstance();
  const get =
    groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesChallengeIdNotificationSettingsMeGet(
      {
        axios: fetchInstance,
      }
    );

  const { data, refetch } = useQuery(
    [getChallengeNotificationSettingPath(id, challengeId)],
    () => {
      // return get(id, challengeId)
      // TODO: 챌린지 API 연동 필요
      return { data: mockResponse };
    },
    {
      suspense: true,
    }
  );
  const response = data?.data;

  return { data: response, refetch };
};

const mockResponse = {
  isReminderEnabled: true,
};
