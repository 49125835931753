import { groupClient } from '@community-group/api';
import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

const getChallengeDetailPath = (groupId: string, challengeId: string) =>
  groupClient.api.GroupChallengeApi.getapiV1GroupsGroupIdChallengesChallengeIdDetailGetPath(
    Number(groupId),
    Number(challengeId)
  );

export const useGetChallengeDetail = ({
  groupId,
  challengeId,
}: {
  groupId: string;
  challengeId: string;
}) => {
  const fetchInstance = useFetchInstance();

  const getChallengeDetail =
    groupClient.api.GroupChallengeApi.apiV1GroupsGroupIdChallengesChallengeIdDetailGet({
      axios: fetchInstance,
    });
  const { data, ...rest } = useQuery([getChallengeDetailPath(groupId, challengeId)], () => {
    // TODO: 챌린지 상세 호출 API 추가 시 사용
    return { data: mockChallengeDetail };
    // return getChallengeDetail(Number(groupId), Number(challengeId));
  });

  const response = data?.data as ChallengeDetailPresentation;

  return {
    data: response,
    ...rest,
  };
};

const mockChallengeDetail: ChallengeDetailPresentation = {
  id: 1234,
  name: '매일 아침 운동하기~~~💪',
  description: '매일 아침 30분씩 운동하면서 건강한 습관을 만들어보아요!',
  images: [
    {
      id: '1111',
      url: 'https://example.com/challenge-image1.jpg',
      width: 800,
      height: 600,
      small: 'https://example.com/challenge-image1-small.jpg',
      medium: 'https://example.com/challenge-image1-medium.jpg',
      large: 'https://example.com/challenge-image1-large.jpg',
    },
  ],
  status: 'closed',
  groupName: '당근모임',
  publicId: '1234',
  certifyIntervalDays: 7, // 1주
  totalDays: 28,
  certifyIntervalTargetCount: 7, // 매일
  isJoined: true,
  startedAt: '2024-03-28T23:59:59Z',
  endedAt: '2024-03-28T23:59:59Z',
  createdAt: '2024-02-28T09:00:00Z',
  author: {
    id: 5678,
    nickname: '헬스왕',
    profileImage: 'https://example.com/profile-image.jpg',
    role: 'superHost',
    isAccountDeleted: false,
    state: 'member',
  },
  participantsPreview: {
    participantsCount: 15,
    participantsProfileImages: [
      'https://example.com/participant1.jpg',
      'https://example.com/participant2.jpg',
      'https://example.com/participant3.jpg',
    ],
    representativeParticipantsName: '헬스왕 외 14명',
  },
};
